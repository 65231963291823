import React from "react";
import { GatsbyBrowser } from "gatsby";
import Layout from "./src/components/Layout";
import "react-slideshow-image/dist/styles.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return <Layout {...props}>{element}</Layout>;
};
