import { ReactNode } from "react";

import "@fontsource/roboto";
import "../styles/global.css";
import Nav from "./Nav";

interface LayoutProps {
  children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <main>
      <Nav />
      {children}
    </main>
  );
}
