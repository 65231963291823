import { Link } from "gatsby";
import { useState } from "react";
import Burger from "./utils/Burger";
import Cross from "./utils/Cross";

const Nav = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="fixed right-4 md:right-8 top-4 md:top-8 z-30">
        <button
          aria-label="Menu"
          type="button"
          className="p-1 px-1.5 bg-cvlsnd-font-color"
          onClick={() => setOpen(true)}
        >
          <Burger />
        </button>
      </div>
      <nav
        className={`fixed bg-opacity-90 bg-black w-screen h-screen z-40 flex flex-col justify-center items-center gap-y-4 uppercase text-xl transition-opacity ${
          open ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <button
          type="button"
          aria-label="Close menu"
          onClick={() => setOpen(false)}
        >
          <Cross />
        </button>
        <Link
          to="/#welcome"
          onClick={() => setOpen(false)}
          aria-label="link to welcome part"
          aria-describedby="welcome"
        >
          Bienvenue
        </Link>
        <Link
          to="/#studio"
          onClick={() => setOpen(false)}
          aria-label="link to studio part"
          aria-describedby="studio"
        >
          Studio services
        </Link>
        <Link
          to="/#live"
          onClick={() => setOpen(false)}
          aria-label="link to live part"
          aria-describedby="live"
        >
          Live services
        </Link>
        <Link
          to="/#media"
          onClick={() => setOpen(false)}
          aria-label="link to media part"
          aria-describedby="media"
        >
          Media
        </Link>
        <Link
          to="/#releases"
          onClick={() => setOpen(false)}
          aria-label="link to releases part"
          aria-describedby="releases"
        >
          Réalisations
        </Link>
        <Link
          to="/#contact"
          onClick={() => setOpen(false)}
          aria-label="link to contact part"
          aria-describedby="contact"
        >
          Contact
        </Link>
      </nav>
    </>
  );
};

export default Nav;
